<template>
  <b-modal
    :id="id"
    size="md"
    title="Kích hoạt"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="activeAccount"
    @hidden="resetData"
  >
    <validation-observer ref="rule">
      <!-- Kiểu người dùng-->
      <b-col>
        <b-form-group label-for="basicInput">
          <label for="InputHelp">Kiểu người dùng <span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="userTypeId"
            rules="required"
            :custom-messages="{required: `Kiểu người dùng là bắt buộc`,}"
          >
            <v-select
              v-model="userTypeId"
              :reduce="userType => userType.id"
              label="name"
              placeholder="Lựa chọn kiểu người dùng"
              :options="dataCombobox || []"
              :class="{'is-invalid': !!errors.length}"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      userTypeId: '',
    }
  },
  computed: {
    ...mapGetters('userType', ['dataCombobox']),
  },
  mounted() {
    this.doFetchUserTypeCombobox()
  },
  methods: {
    ...mapActions('userType', [
      'doFetchUserTypeCombobox',
    ]),

    activeAccount(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('activeAccount', this.userTypeId)
        }
      })
    },

    resetData() {
      this.userTypeId = ''
    },
  },
}
</script>
